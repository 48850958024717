import React from 'react';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Emoji from '../components/emoji';

import { boxStyle } from '../styles';

const OrderError = props => {
  return (
    <Layout>
      <SEO title="Order Error" />
      <div
        css={css`
          ${boxStyle}
          p {
            margin: 20px;
          }
        `}
      >
        <p>
          There was an error processing your order{' '}
          <Emoji label="Woo hoo!" emoji="😟" />
        </p>
      </div>
    </Layout>
  );
};
export default OrderError;
